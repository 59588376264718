// Google maps
// API KEY: AIzaSyCK60A5Dn1PimGjbDRoGOiXmslYUCC-u9I

// Draw google maps
function drawMaps(location, map) {
  return new google.maps.Map(map, {
    center: location,
    zoom: getZoomFactor(),
    zoomControl: true,
    disableDoubleClickZoom: true,
    mapTypeControl: false,
    scaleControl: false,
    scrollwheel: false,
    panControl: false,
    streetViewControl: false,
    draggable : false,
    overviewMapControl: true,
    overviewMapControlOptions: {
      opened: true,
    },
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    styles: [ { 'featureType': 'landscape', 'elementType': 'labels', 'stylers': [ { 'visibility': 'off' } ] },{ 'featureType': 'transit', 'elementType': 'labels', 'stylers': [ { 'visibility': 'off' } ] },{ 'featureType': 'poi', 'elementType': 'labels', 'stylers': [ { 'visibility': 'off' } ] },{ 'featureType': 'water', 'elementType': 'labels', 'stylers': [ { 'visibility': 'off' } ] },{ 'featureType': 'road', 'elementType': 'labels.icon', 'stylers': [ { 'visibility': 'off' } ] },{ 'stylers': [ { 'hue': '#00aaff' }, { 'saturation': -100 }, { 'gamma': 2.15 }, { 'lightness': 12 } ] },{ 'featureType': 'road', 'elementType': 'labels.text.fill', 'stylers': [ { 'visibility': 'on' }, { 'lightness': 24 } ] },{ 'featureType': 'road', 'elementType': 'geometry', 'stylers': [ { 'lightness': 57 } ] } ]
  });
}

// Draw all marker locations
function drawMarkerLocations(locations, map) {
  for (var location in locations) {
    var marker = new google.maps.Marker({
      icon: locations[location].marker,
      position: new google.maps.LatLng(locations[location].lat, locations[location].long),
      map: map
    });
    locations[location].markerObject = marker;
    bindInfoWindow(marker, map, locations[location], locations);
  }
}

// Draw all radius locations
function drawRadiusLocations(locations, map) {
  for (var location in locations) {
    var cityCircle = new google.maps.Circle({
      strokeColor: '#00945E',
      strokeOpacity: 0.15,
      strokeWeight: 1,
      fillColor: '#00945E',
      fillOpacity: .10,
      map: map,
      center: locations[location].center,
      radius: locations[location].radius
    });
  }
}

// Bind info windows to markers
function bindInfoWindow(marker, map, location, locations) {
  var infoWindowVisible = (function() {
    var currentlyVisible = false;
    return function(visible) {
      if (visible !== undefined) {
        currentlyVisible = visible;
      }
      return currentlyVisible;
    };
  }());

  var iw = new google.maps.InfoWindow();
  google.maps.event.addListener(marker, 'click', function() {
    if (infoWindowVisible()) {
    } else {
      for (var locationName in locations) {
        locations[locationName].markerObject.setIcon(locations[locationName].marker);
      }
      if (location.markerActive) {
        marker.setIcon(location.markerActive);
      }
      document.querySelector('.google-maps__window').classList.add('google-maps__window--active');
      document.querySelector("#google-maps__location-title").innerHTML = location.title;
      document.querySelector("#google-maps__location-address").innerHTML = location.address + '<br>' + location.phone + '<br>' + location.email;
    }
  });

  google.maps.event.addListener(iw, 'closeclick', function() {
    infoWindowVisible(false);
  });
}

function getZoomFactor() {
  var width = window.innerWidth;

  if (width < 600) {
    return 8;
  } else if (width < 1024) {
    return 8;
  }

  return 9;
}
