/* global Modernizr, noUiSlider, priceValues, */


window.onload = function() {
  setRequiredDates();
  bindCustomDateFields();
};

// Make dates required if necessary
//  For touch-devices, use the <input type=date>-field
//  For non-touch-devices, use three input-fields to input the date
function setRequiredDates() {
  var datesList = document.querySelectorAll('.form__item--date--required');
  for (var i = 0; i < datesList.length; ++i) {
    var dates = datesList[i];
    var name = dates.getAttribute('name');
    if (Modernizr && Modernizr.touchevents) {
      var completeDate = document.querySelector('#' + name);
      completeDate.setAttribute('required', 'required');
    } else {
      document.querySelector('#' + name + '_1').setAttribute('required', 'required');
      document.querySelector('#' + name + '_2').setAttribute('required', 'required');
      document.querySelector('#' + name + '_3').setAttribute('required', 'required');
    }
  }
}

// For non-touch-devices, use a custom date entry
//  It uses three input-fields to fill the <input type=date>-field
function bindCustomDateFields() {
  var datesList = document.querySelectorAll('.form__item--date');
  for (var i = 0; i < datesList.length; ++i) {
    var dates = datesList[i];
    var dateHelpers = dates.querySelectorAll('.form__control--date-helper');
    var name = dates.getAttribute('name');
    var completeDate = document.querySelector('#' + name);

    for (var j = 0; j < dateHelpers.length; ++j) {
      var dateHelper = dateHelpers[j];
      dateHelper.oninput = function() {
        var day = document.querySelector('#' + name + '_1');
        var month = document.querySelector('#' + name + '_2');
        var year = document.querySelector('#' + name + '_3');

        completeDate.value = year.value + '-' + month.value + '-' + day.value;
      };
    }
  }
}

// Owl slider
$(function() {
  $('.slides').owlCarousel({
    itemElement: 'div',
    loop: true,
    items: 1,
    margin: 0,
    nav: false,
    dots: true,
    autoplay: true,
    autoplayTimeout: 15000
  });

  $('.products--slider').owlCarousel({
    itemElement: 'div',
    loop: true,
    items: 3,
    margin: 40,
    nav: true,
    dots: false,
    navText: ['', ''],
    autoplay: true,
    responsive: {
      0: {
        items: 1
      },
      550: {
        items: 2,
        margin: 20
      },
      800: {
        items: 3,
        margin: 40
      }
    }
  });

  if ($('.product-images').length) {
    var $owl = $('.product-images').owlCarousel({
      items: 1,
      loop: true,
      nav: true,
      navText: ['', ''],
      thumbs: true,
      thumbsPrerendered: true,
      thumbContainerClass: 'product__thumbnails',
      thumbItemClass: 'product__thumbnail',
    });

    $owl.trigger('refresh.owl.carousel');
  }
});

// MAINMENU
$(function() {
  $('ul.menu--main li').hover(function(){
    $(this).addClass('hover');
    $('ul:first',this).css('visibility', 'visible');
  }, function(){
    $(this).removeClass('hover');
    $('ul:first',this).css('visibility', 'hidden');
  });

  $('ul.menu--main > li:has(ul)').find('a:first').append(' <i class="fa fa-angle-down" aria-hidden="true"></i>');
  $('ul.menu--main li ul li:has(ul)').find('a:first').append(' <i class="fa fa-angle-right" aria-hidden="true"></i>');
});


// FAQ
$(function() {
  $('.faq__question').on('click', function() {
    $(this).closest('.faq__item').toggleClass('faq__item--active');
  });
});

// Toggle filters / sort / menu (mobile)
$(function() {
  $('.mobile-filters__close').on('click', function(e) {
    $('body').removeClass('body--show-filters');
    e.preventDefault();
  });

  $('.catalog__mobile-action--filter').on('click', function(e) {
    $('body').addClass('body--show-filters');
    e.preventDefault();
  });

  $('.mobile-sort__close').on('click', function(e) {
    $('body').removeClass('body--show-sort');
    e.preventDefault();
  });

  $('.catalog__mobile-action--sort').on('click', function(e) {
    $('body').addClass('body--show-sort');
    e.preventDefault();
  });

  $('.mobile-menu__close').on('click', function(e) {
    $('body').removeClass('body--show-menu');
    e.preventDefault();
  });

  $('.toggle-menu').on('click', function(e) {
    $('body').addClass('body--show-menu');
    e.preventDefault();
  });
});

// Toggle mobile sort options
$(function() {
  $('.sort__option').on('click', function(e) {
    $(this).closest('.sort').find('.sort__option').removeClass('sort__option--selected');
    $(this).addClass('sort__option--selected');
    e.preventDefault();
  });
});

// Enable tooltips
$(function() {
  $('.tooltip').tooltipster({
    animation: 'fade',
    delay: 200,
    trigger: 'hover',
    theme: 'tooltipster-theme',
    side: 'right'
  });
});

// Initialize price slider
var priceSliderDesktop;
var priceSliderMobile;
$(function() {
  priceSliderDesktop = document.getElementById('filter-price-slider-element-desktop');
  var priceMin = document.getElementById('filter-price-min-desktop');
  var priceMax = document.getElementById('filter-price-max-desktop');

  if (priceSliderDesktop) {
    noUiSlider.create(priceSliderDesktop, {
      start: [ priceValues.current.min, priceValues.current.max ],
      connect: true,
      range: {
        'min': priceValues.original.min,
        'max': priceValues.original.max
      }
    });

    priceSliderDesktop.noUiSlider.on('set', function() {
      $(priceSliderDesktop).closest('.filters__group').find('.filter__apply').addClass('filter__apply--active');
    });

    priceSliderDesktop.noUiSlider.on('update', function( values, handle ) {
      var value = values[handle];

      if ( handle == 0 ) {
        priceMin.value = Math.round(value);
      } else {
        priceMax.value = Math.round(value);
      }
    });

    $(priceMin).on('change', function() {
      priceSliderDesktop.noUiSlider.set([this.value, null]);
    });

    $(priceMax).on('change', function() {
      priceSliderDesktop.noUiSlider.set([null, this.value]);
    });
  }

  $('.filter__apply').on('click', function(e) {
    document.location = document.location.origin + document.location.pathname + '?minprice=' + $('#filter-price-min-desktop').val() + '&maxprice=' + $('#filter-price-max-desktop').val();

    e.preventDefault();
  });
});

$(function() {
  priceSliderMobile = document.getElementById('filter-price-slider-element-mobile');
  var priceMin = document.getElementById('filter-price-min-mobile');
  var priceMax = document.getElementById('filter-price-max-mobile');

  if (priceSliderMobile) {
    noUiSlider.create(priceSliderMobile, {
      start: [ priceValues.current.min, priceValues.current.max ],
      connect: true,
      range: {
        'min': priceValues.original.min,
        'max': priceValues.original.max
      }
    });

    priceSliderMobile.noUiSlider.on('update', function( values, handle ) {
      var value = values[handle];

      if ( handle == 0 ) {
        priceMin.value = Math.round(value);
      } else {
        priceMax.value = Math.round(value);
      }
    });

    priceMin.addEventListener('change', function() {
      priceSliderMobile.noUiSlider.set([this.value, null]);
    });

    priceMax.addEventListener('change', function() {
      priceSliderMobile.noUiSlider.set([null, this.value]);
    });
  }
});

// Mobile menu
$(function() {
  $('.menu.menu--mobile .menu__item:has(ul)')
    .find('a:first')
    .addClass('menu__link--parent')
    .on('click', function(e) {
      e.preventDefault();
      var currentActiveMobileItem;

      if ($(this).parent().hasClass('menu__item--active')) {
        $(this).closest('.menu__item').removeClass('menu__item--active');

        currentActiveMobileItem = $(this).closest('.menu__item--active').find('> .menu__link');
      } else {

        // When menu-item is in sub-sub-menu, disable all active menu-items in sub-sub-menu
        if ($(this).closest('.menu__sub-sub').length > 0) {
          $(this).closest('.menu__sub-sub').find('.menu__item--active').removeClass('menu__item--active');
        } else {
          $(this).closest('.menu__sub').find('.menu__item--active').removeClass('menu__item--active');
        }

        $(this).closest('.menu__item').addClass('menu__item--active');

        currentActiveMobileItem = $(this);
      }

      window.setTimeout(function() {
        var scrollTop = 0;
        if (currentActiveMobileItem.length > 0) {
          scrollTop = currentActiveMobileItem.offset().top - 50 + $('.mobile-menu__options').scrollTop();
        }
        $('.mobile-menu__options').animate({ scrollTop: scrollTop }, 500);
      }, 200);
    });

  $('.menu.menu--mobile .menu__sub > .menu__item:has(ul), .menu.menu--mobile .menu__sub-sub > .menu__item:has(ul)').each(function() {
    var $submenu = $(this).find(' > ul');
    var $item = $(this).find('> a:first-child');
    var $itemTitle = $item.text();
    var $itemLink = $item.attr('href');

    $submenu.prepend('<li class="menu__item"><a class="menu__link menu__link--to-category" href="'+ $itemLink +'">Bekijk alle '+ $itemTitle + '</a></li>');
  });

  $('.menu.menu--mobile .menu__item--header a').each(function() {
    $(this).click(function(e) {
      var parentmenu = $(this).closest('.menu');
      parentmenu.removeClass('show');
      parentmenu.closest('.menu__item').parent('.menu--sub').addClass('show');
      parentmenu.closest('.menu__item').parent('.menu').removeClass('close');
      e.preventDefault();
    });
  });
});


// Toggle delivery address
$(function() {
  $('.form__item--toggle-delivery input').on('click', function() {
    if ($(this).is(':checked')) {
      $('.checkout__delivery').addClass('checkout__delivery--active');
    } else {
      $('.checkout__delivery').removeClass('checkout__delivery--active');
    }
  });
});

// Toggle payment choices
$(function() {
  $('.payment__options input').on('click', function() {
    $('.payment__option').removeClass('payment__option--active');
    $(this).closest('.payment__option').addClass('payment__option--active');
  });

  $('.payment__options input:checked').trigger('click');
});

// Show discount field
$(function() {
  $('.discount-trigger').on('click', function(e) {
    $(this).closest('.cart__item').addClass('cart__item--discount--active');

    e.preventDefault();
  });
});

// Toggle filters
$(function() {
  $('.filters:not(.mobile-filters__filters) input[type=checkbox]').on('change', function() {
    commitFilter(false);
  });

  $('.mobile-filters__results .button').on('click', function(e) {
    commitFilter(true);
    e.preventDefault();
  });
});

// Refresh page with given filters
function commitFilter(mobile, priceFilter) {
  var cFilter = '';
  var filterName = mobile ? 'mobileFilter' : 'filter';

  var nLength = $('#' + filterName + 'GeslachtMan').closest('.filters__group').find('input:checked').length;
  if (nLength > 0 && nLength < 3) {
    cFilter += ($('#' + filterName + 'GeslachtMan').is(':checked') ? (cFilter == '' ? '' : ', ') + 'geslacht:man' : '');
    cFilter += ($('#' + filterName + 'GeslachtVrouw').is(':checked') ? (cFilter == '' ? '' : ', ') + 'geslacht:vrouw' : '');
    cFilter += ($('#' + filterName + 'GeslachtUni').is(':checked') ? (cFilter == '' ? '' : ', ') + 'geslacht:unisex' : '');
  }

  nLength = $('#' + filterName + 'PolarizedJa').closest('.filters__group').find('input:checked').length;
  if (nLength > 0 && nLength < 2) {
    cFilter += ($('#' + filterName + 'PolarizedJa').is(':checked') ? (cFilter == '' ? '' : ', ') + 'polarized:ja' : '');
    cFilter += ($('#' + filterName + 'PolarizedNee').is(':checked') ? (cFilter == '' ? '' : ', ') + 'polarized:nee' : '');
  }

  nLength = $('#' + filterName + 'TransitionJa').closest('.filters__group').find('input:checked').length;
  if (nLength > 0 && nLength < 2) {
    cFilter += ($('#' + filterName + 'TransitionJa').is(':checked') ? (cFilter == '' ? '' : ', ') + 'transition:ja' : '');
    cFilter += ($('#' + filterName + 'TransitionNee').is(':checked') ? (cFilter == '' ? '' : ', ') + 'transition:nee' : '');
  }

  nLength = $('#' + filterName + 'VentedJa').closest('.filters__group').find('input:checked').length;
  if (nLength > 0 && nLength < 2) {
    cFilter += ($('#' + filterName + 'VentedJa').is(':checked') ? (cFilter == '' ? '' : ', ') + 'vented:ja' : '');
    cFilter += ($('#' + filterName + 'VentedNee').is(':checked') ? (cFilter == '' ? '' : ', ') + 'vented:nee' : '');
  }

  nLength = $('#' + filterName + 'PrizmJa').closest('.filters__group').find('input:checked').length;
  if (nLength > 0 && nLength < 2) {
    cFilter += ($('#' + filterName + 'PrizmJa').is(':checked') ? (cFilter == '' ? '' : ', ') + 'prizm:ja' : '');
    cFilter += ($('#' + filterName + 'PrizmNee').is(':checked') ? (cFilter == '' ? '' : ', ') + 'prizm:nee' : '');
  }

  nLength = $('#' + filterName + 'MontuurKunststof').closest('.filters__group').find('input:checked').length;
  if (nLength > 0 && nLength < 2) {
    cFilter += ($('#' + filterName + 'MontuurKunststof').is(':checked') ? (cFilter == '' ? '' : ', ') + 'montuur:kunststof' : '');
    cFilter += ($('#' + filterName + 'MontuurMetaal').is(':checked') ? (cFilter == '' ? '' : ', ') + 'montuur:metaal' : '');
  }

  priceFilter = '';
  if (mobile) {
    var currentMin = $('#filter-price-min-mobile').val();
    var currentMax = $('#filter-price-max-mobile').val();

    if (currentMin !== priceValues.current.min || currentMax !== priceValues.current.max) {
      priceFilter = 'minprice=' + currentMin + '&maxprice=' + currentMax;
      priceFilter = (cFilter !== '' ? '&' : '?') + priceFilter;
    }
  }

  document.location = document.location.origin + document.location.pathname + (cFilter !== '' ? '?filter=' + cFilter : '') + priceFilter;
}

// Style input[type='number']-fields and invoke price slider update
$(function() {
  $('<div class="quantity-nav"><div class="quantity-button quantity-up">+</div><div class="quantity-button quantity-down">-</div></div>').insertAfter('.filters__price-wrapper input');
  $('.filters__price-wrapper').each(function() {
    var spinner = $(this),
      input = spinner.find('input[type="number"]'),
      btnUp = spinner.find('.quantity-up'),
      btnDown = spinner.find('.quantity-down'),
      min = input.attr('min'),
      max = input.attr('max');

    // spinner.closest('.filters__group').find('.filters__price-slider-element').first();

    btnUp.click(function() {
      var newVal;
      var oldValue = parseFloat(input.val());
      if (oldValue >= max) {
        newVal = oldValue;
      } else {
        newVal = oldValue + 1;
      }
      spinner.find('input').val(newVal);
      spinner.find('input').trigger('change');
    });

    btnDown.click(function() {
      var newVal;
      var oldValue = parseFloat(input.val());
      if (oldValue <= min) {
        newVal = oldValue;
      } else {
        newVal = oldValue - 1;
      }
      spinner.find('input').val(newVal);
      spinner.find('input').trigger('change');
    });

  });
});


$(function() {
  $('.catalog > .paragraph').each(function() {
    $('.catalog__text').append(this);
  });
});
